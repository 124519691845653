<template>
  <div class="card" style="min-height: 60vh; width: ">
    <div class="card-header">
      <div class="d-flex flex-column align-items-start justify-content-start">
        <h4 class="text-muted">Subscription Summary</h4>
      </div>
    </div>
    <div class="card-header">
      <div class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="font-weight-bolder">{{selectedPlan.title}}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
    selectedPriceId:{
        type:String,
        required: true,
    }
  },
};
</script>

<style>
</style>