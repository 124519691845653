export default {
  methods: {
    getSubscriptions(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/subscriptions`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getSubscription(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/subscriptions/${id}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    storeSubscription(data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/subscriptions`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateSubscription(id, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        // formData.append("_method", "PUT");
        Object.keys(data).forEach((key) => {
          if (key == "features") {
            data[key].forEach((feature, i) => {
              formData.append(`features[${i}][_id]`, feature._id);

              if (
                feature.selected_field_values &&
                feature.selected_field_values != null &&
                feature.selected_field_values.length > 0
              ) {
                feature.selected_field_values.forEach((val, v_i) => {
                  formData.append(
                    `features[${i}][meta][selected_field_values][${v_i}]`,
                    val
                  );
                });
              }

              if (feature.inputValue && feature.inputValue != null) {
                if (feature.unit == "credit") {
                  formData.append(`features[${i}][quantity]`, 1);
                  formData.append(`features[${i}][tariff]`, feature.inputValue);
                } else {
                  formData.append(
                    `features[${i}][quantity]`,
                    parseInt(feature.inputValue)
                  );
                }
              }
            });
          }
          if (data[key] && data[key] != null && key != "features") {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/subscriptions/${id}`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
