<template>
  <div
    class="card border shadow-lg w-100"
    style="height: 60vh; cursor: pointer"
  >
    <div
      class="card-header d-flex flex-column align-items-center justify-content-start"
    >
      <b-badge variant="primary" class="mb-50">{{ planIndex }}#</b-badge>
      <h4 class="mb-25 font-weight-bold">{{ plan.title }}</h4>
      <p class="mb-0 text-center">{{ plan.description }}</p>
    </div>
    <label class="ml-1 mb-25 font-weight-bolder align-middle"
      ><b-badge variant="dark" class="mr-25">{{
        plan.features ? plan.features.length : 0
      }}</b-badge
      ><span class="align-middle">Features</span></label
    >
    <hr class="my-25" />
    <div class="card-body business-card p-0">
      <div
        class="business-items h-100 position-relative"
        style="height: 70%; overflow: auto"
      >
        <div v-for="feature in plan.features" :key="feature._id['$oid']">
          <div class="business-item border-0 py-50">
            <div
              class="w-100 d-flex align-items-center justify-content-between"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <b-badge class="mr-25" pill variant="light-success"
                  ><feather-icon icon="CheckCircleIcon"
                /></b-badge>
                <div
                  class="d-flex flex-column align-items-start justify-content-center ml-25"
                >
                  <label class="mb-0 font-weight-bold">{{
                    feature.title
                  }}</label>
                </div>
              </div>

              <b-badge variant="dark">
                <small v-if="feature.unit && feature.unit == 'count'">
                  <small class="mr-50">Count:</small>
                  <small class="font-weight-bolder">{{
                    feature.quantity && feature.quantity != null
                      ? feature.quantity
                      : 0
                  }}</small>
                </small>

                <span v-else-if="feature.unit && feature.unit == 'credit'">
                  <small class="mr-50">Credits Per Unit:</small>
                  <small class="font-weight-bolder">{{
                    feature.tariff && feature.tariff != null
                      ? feature.tariff
                      : 0
                  }}</small>
                </span>

                <span v-else-if="feature.unit && feature.unit == 'boolean'">
                  <div class="d-flex align-items-center justify-content-start">
                    <small class="mr-50">Enabled</small>
                    <div
                      class="rounded-circle border-light bg-success d-flex align-items-center justify-content-center"
                      style="width: 16px; height: 16px"
                    >
                      <feather-icon class="text-light" icon="CheckIcon" />
                    </div>
                  </div>
                </span>
              </b-badge>
            </div>
          </div>
        </div>

        <!-- <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area h-100"
          style="position: relative;"
          tagname="div"
        >
          <div v-for="feature in plan.features" :key="feature._id['$oid']">
            <div class="business-item border-0 py-50">
              <div class="d-flex align-items-center justify-content-start">
                <b-badge class="mr-25" pill variant="light-success"
                  ><feather-icon icon="CheckCircleIcon"
                /></b-badge>
                <label class="mb-0 text-truncate">{{ feature.title }}</label>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar> -->
      </div>
    </div>
    <div class="card-footer py-75">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <b-button pill size="sm" variant="success" @click="handleSubscribe"
          ><feather-icon icon="CheckIcon" class="mr-50" />Get Started</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BTooltip,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BBadge,
    BButton,
    FeatherIcon,
    VuePerfectScrollbar,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
    planIndex: {
      type: Number,
      required: true,
    },
    handleSubscribeClick: {
      type: Function,
      required: true,
    },
  },

  methods: {
    handleSubscribe() {
      this.handleSubscribeClick(this.plan);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

