<template>
  <div class="w-100 h-auto">
    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12 ml-0 pl-0"
          >
            <h3 class="mb-25 font-weight-bolder">Plans</h3>
            <p class="mb-50">
              Choose your desired plan from the following list.
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <!-- <b-button size="sm" variant="success" @click="handleNewPlanClick"
                ><feather-icon icon="PlusIcon" class="mr-50" />Create New
                Plan</b-button
              > -->
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative w-100" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <template v-if="Object.keys(plans).length > 0"> </template>
          <swiper
            class="p-1"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(plan, key, index) in plans"
              :key="key"
              class="rounded swiper-shadow"
              style="width: 480px"
            >
              <plan-feature-card
                :plan="plan"
                :planIndex="index + 1"
                :handleSubscribeClick="handleSubscribeClick"
              />
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>

            <!-- <div slot="button-next" class="swiper-button-next"></div>
            <div slot="button-prev" class="swiper-button-prev"></div> -->
          </swiper>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import PlanMixins from "@/mixins/PlanMixins";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import PlanFeatureCard from "./PlanFeatureCard.vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  mixins: [ResponseMixins, PlanMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    BFormCheckbox,
    EmptyTableSection,
    Swiper,
    SwiperSlide,
    PlanFeatureCard,
    FeatherIcon,
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      selectedPlanId: null,
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
        from: 1,
      },

      filters: {
        search: null,
        page: 1,
      },

      plans: {},
      activatedPlans: [],
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getPlansAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getPlansAndSetData(this.filters);
    },

    handleSubscribeClick(plan) {
      this.$emit("on-subscribe", plan);
    },

    handleEditClick(id) {
      this.$router.push(`/edit-plan/${id}`);
    },

    checkboxChanged(value, id) {
      this.showOverlay = true;
      console.log(value);
      this.setPlanStatus(value, id)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewPlanClick() {
      this.$router.push("/new-plan");
    },

    handleEditFeatureClick(id) {
      this.selectedPlanId = id;
      this.showEditFeatureModal();
    },

    showEditFeatureModal() {
      this.$refs["edit-feature-modal"].show();
    },
    hideEditFeatureModal() {
      this.$refs["edit-feature-modal"].hide();
    },

    handleDetailViewClick(id) {
      this.selectedPlanId = id;
      this.$router.push(`/plan/${id}`);
    },

    getPlansAndSetData(params) {
      this.showOverlay = true;
      this.getPlans(params)
        .then((res) => {
          console.log(res);
          const newPlans = {};
          res.data.data.data.forEach((plan) => {
            newPlans[plan._id] = plan;
          });
          this.plans = newPlans;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
// Overrides user variable
@import "~@core/scss/base/components/include";

.vue-form-wizard {
  .wizard-header {
    padding: 0;
  }
  .wizard-navigation {
    .wizard-tab-content {
      margin: 0;
      padding: 0;
    }
    .wizard-icon-circle {
      border: 2px solid $primary;
      .wizard-icon {
        color: $primary;
      }
    }

    .stepTitle {
      color: $body-color;
    }
  }
}

body {
  &.dark-layout {
    .vue-form-wizard {
      .stepTitle {
        color: $theme-dark-body-color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
