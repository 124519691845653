var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-auto"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start w-100"},[_c('h2',{staticClass:"text-center font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.plan.title)+" ")])]),_c('div',{staticClass:"card mb-1"},[_c('div',{staticClass:"card-header pt-0 mb-0 mt-0 pb-0"},[_c('div',{staticClass:"w-100 row justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start col-sm-12 mx-0 px-0 border-bottom"},[_c('span',{staticClass:"d-flex align-items-center justify-content-center mb-50"},[_c('h4',{staticClass:"font-weight-bolderalign-middle mb-0"},[_vm._v(" Features "),_c('b-badge',{staticClass:"ml-0",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.plan.features.length))])],1),_c('b-form-checkbox',{staticClass:"custom-control-primary ml-1",attrs:{"checked":"false","name":"check-button","switch":"","aria-expanded":_vm.featuresListVisible ? 'true' : 'false',"aria-controls":("collapse-features-" + (_vm.plan._id))},on:{"change":function () {
                  _vm.featuresListVisible = !_vm.featuresListVisible;
                }}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"EyeOffIcon"}})],1)])],1)])])]),_c('div',{staticClass:"card-body pb-0 px-0"},[_c('b-collapse',{staticClass:"w-100",attrs:{"id":("collapse-features-" + (_vm.plan._id))},model:{value:(_vm.featuresListVisible),callback:function ($$v) {_vm.featuresListVisible=$$v},expression:"featuresListVisible"}},[_c('div',{staticClass:"table-responsive mb-0 w-100"},[_c('table',{staticClass:"table table-bordered table-condensed",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Description")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Quantity")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(_vm.plan.features.length > 0)?_vm._l((_vm.plan.features),function(feature,index){return _c('tr',{key:feature._id['$oid'],attrs:{"role":"row"}},[_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticStyle:{"max-width":"20vw"},attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start w-100"},[_c('p',{staticClass:"mb-0 font-weight-bold w-100"},[_vm._v(" "+_vm._s(("" + (feature.title)))+" ")])])]),_c('td',{staticClass:"w-auto",attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start w-100"},[_c('p',{staticClass:"mb-0 font-weight-bold w-100"},[_vm._v(" "+_vm._s(("" + (feature.description)))+" ")])])]),_c('td',{attrs:{"role":"cell"}},[(feature.unit && feature.unit != null)?_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(
                          feature.unit &&
                          feature.unit == 'count' &&
                          feature.quantity
                        )?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-primary"},[_vm._v("Count")]),_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(feature.quantity))])],1):(
                          feature.unit &&
                          feature.unit == 'credit' &&
                          feature.tariff
                        )?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-primary"},[_vm._v("Credits Per Unit")]),_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(feature.tariff))])],1):(feature.unit && feature.unit == 'boolean')?_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-success"},[_vm._v("Enabled")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":"","disabled":true}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1)])],1):_vm._e()]):_vm._e()])])}):[_c('empty-table-section',{attrs:{"title":"Features Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" This plan contains no features. ")])]},proxy:true}])})]],2)])])])],1)]),_c('div',{staticClass:"card mb-1"},[_vm._m(0),_c('div',{staticClass:"card-body border pb-0 py-1"},[_c('div',{staticClass:"w-100 d-flex flex-row align-items-center justify-content-center"},[_vm._l((_vm.plan.prices),function(price){return [_c('div',{key:price._id['$oid'],staticClass:"card border-primary cursor-pointer mx-3 mb-0",class:{
              'border-success': _vm.selectedPriceId == price._id['$oid'],
              'bg-light-success': _vm.selectedPriceId == price._id['$oid'],
            },staticStyle:{"width":"230px","height":"180px"}},[_c('div',{staticClass:"card-header pb-0"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start w-100"},[_c('h3',{staticClass:"font-weight-bolder mb-75 text-center"},[_vm._v(" "+_vm._s(price.name == "Annual" ? "Annually" : price.name)+" ")])])]),_c('div',{staticClass:"card-body"},[_c('h1',{staticClass:"text-center font-weight-bolder text-primary align-middle",class:{
                  'text-success': _vm.selectedPriceId == price._id['$oid'],
                }},[_c('feather-icon',{attrs:{"size":"30","icon":"DollarSignIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(price.tariff))])],1)]),_c('div',{staticClass:"card-footer py-75"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center"},[_c('b-button',{attrs:{"pill":"","variant":_vm.selectedPriceId == price._id['$oid']
                      ? 'success'
                      : 'primary'},on:{"click":function($event){return _vm.handleBuy(price._id['$oid'])}}},[(_vm.selectedPriceId == price._id['$oid'])?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}):_vm._e(),_vm._v(_vm._s(_vm.selectedPriceId == price._id["$oid"] ? "Selected" : "Select"))],1)],1)])])]})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header pt-0 mb-0 mt-0 pb-0"},[_c('div',{staticClass:"w-100 row justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start col-sm-12 mx-0 px-0"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[_vm._v("Pricing")]),_c('p',{staticClass:"mb-50"},[_vm._v(" Select a suitable pricing from the options below. ")])])])])}]

export { render, staticRenderFns }