<template>
  <div class="card">
    <div class="card-header pb-75" style="padding-right: 0">
      <div class="w-100 row justify-content-between align-items-center">
        <div
          class="d-flex flex-column align-items-center justify-content-start col-sm-12"
        >
          <h3 class="mb-25 font-weight-bolder">
            Choose Your Subscription Plan
          </h3>
          <p class="mb-50">
            Complete the following steps to initiate a subscription.
          </p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <form-wizard
          color="#7367f0"
          error-color="#ea5455"
          step-size="xs"
          class="w-100 mx-0"
          ref="wizard"
          @on-complete="handleSubmitClick"
          :hide-buttons="false"
        >
          <div slot="title" class="p-0 m-0"></div>

          <tab-content
            title="Plan Selection"
            :selected="true"
            :before-change="() => validateStep('step1')"
          >
            <div ref="step1" class="h-100 w-100 mt-3">
              <plan-selection-list @on-subscribe="handleSubscribeClick" />
            </div>
          </tab-content>

          <tab-content
            title="Summary & Pricing"
            :before-change="() => validateStep('step2')"
          >
            <validation-observer
              v-if="selectedPlan != null"
              ref="step2"
              class="w-100"
            >
              <div class="h-100 w-100 mt-3">
                <plan-price-listing
                  :plan="selectedPlan"
                  :handlePriceSelected="handlePriceSelected"
                  :selectedPriceId="formData.plan_price_id"
                />
                <b-form-group label="Note" class="w-100">
                  <validation-provider
                    vid="notes"
                    #default="{ errors }"
                    name="Note"
                    :rules="{
                      required: true,
                      min: 3,
                    }"
                  >
                    <b-form-textarea
                      rows="3"
                      max-rows="4"
                      v-model="formData.notes"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </validation-observer>
          </tab-content>

          <template slot="footer" scope="props">
            <div class="wizard-footer-left">
              <b-button
                v-if="selectedPlan != null && props.activeTabIndex != 0"
                @click="props.prevTab()"
                size="sm"
                variant="primary"
                ><feather-icon icon="ArrowLeftIcon" class="mr-50" /><span
                  class="align-middle"
                  >Back</span
                ></b-button
              >
            </div>

            <div class="wizard-footer-right">
              <b-button
                v-if="props.activeTabIndex == 1"
                @click="props.nextTab()"
                size="sm"
                variant="success"
                ><feather-icon icon="CheckIcon" class="mr-50" /><span
                  class="align-middle"
                  >Finish</span
                ></b-button
              >
            </div>
          </template>

          <!-- <template slot="custom-buttons-right" slot-scope="props">
            <b-button
              v-if="props.activeTabIndex == 1"
              variant="success"
              size="sm"
            >
              <template v-if="isLoading">
                <b-spinner variant="light" small class="mr-50" />
              </template>
              <template v-else>
                <feather-icon icon="SaveIcon" class="mr-50" />
              </template>
              <span class="align-middle">{{
                isLoading ? "Please Wait" : "Save & Exit"
              }}</span></b-button
            >
          </template>

          <template slot="next" slot-scope="props">
            <b-button
              v-if="selectedPlan != null && props.activeTabIndex === 0"
              size="sm"
              variant="primary"
              ><span class="align-middle">Continue</span
              ><feather-icon icon="ArrowRightIcon" class="ml-50"
            /></b-button>
          </template>

          <b-button size="sm" variant="primary" slot="prev"
            ><feather-icon icon="ArrowLeftIcon" class="mr-50" /><span
              class="align-middle"
              >Back</span
            ></b-button
          >
          <b-button size="sm" variant="success" slot="finish"
            ><feather-icon icon="CheckIcon" class="mr-50" /><span
              class="align-middle"
              >Finish</span
            ></b-button
          > -->
        </form-wizard>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import PlanSelectionList from "./components/PlanSelectionList.vue";
import PlanPriceListing from "./components/PlanPriceListing.vue";
import SubscriptionSummary from "./components/SubscriptionSummary.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import SubscriptionMixins from "../../mixins/SubscriptionMixins";

export default {
  mixins: [ResponseMixins, SubscriptionMixins],
  components: {
    FormWizard,
    TabContent,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    required,
    PlanSelectionList,
    PlanPriceListing,
    SubscriptionSummary,
  },

  data() {
    return {
      showOverlay: false,
      formData: {
        plan_id: null,
        plan_price_id: null,
        tenant_id: null,
        notes: null,
      },
      selectedPlan: null,
      selectedPriceId: null,
      note: null,
      isLoading: false,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.$set(this.formData, "tenant_id", this.$route.params.id);
    },

    handleNoteChange(newValue) {
      this.$set(this.formData, "notes", newValue);
    },

    handleSubmitClick() {
      this.showOverlay = true;
      this.storeSubscription(this.formData)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handlePriceSelected(id) {
      this.$set(this.formData, "plan_price_id", id);
      //   this.$refs.wizard.nextTab();
    },

    handleSubscribeClick(plan) {
      this.selectedPlan = plan;
      this.$set(this.formData, "plan_id", plan._id);
      //   this.validateStep("step1");
      this.$refs.wizard.nextTab();
    },

    validateStep(name) {
      if (name == "step1") {
        if (this.selectedPlan != null) {
          return true;
        }
        return false;
      } else if (name == "step2") {
        if (this.formData.plan_price_id == null) {
          this.showToast("Select a pricing plan to conitnue.", "warning");
        } else {
          {
            var refToValidate = this.$refs[name];
            return refToValidate.validate();
          }
        }

        return false;
      }
    },
  },
};
</script>

<style>
</style>
