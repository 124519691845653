<template>
  <div class="w-100 h-auto">
    <div
      class="d-flex flex-column align-items-center justify-content-start w-100"
    >
      <h2 class="text-center font-weight-bolder">
        {{ plan.title }}
      </h2>
    </div>
    <div class="card mb-1">
      <div class="card-header pt-0 mb-0 mt-0 pb-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-12 mx-0 px-0 border-bottom"
          >
            <span
              class="d-flex align-items-center justify-content-center mb-50"
            >
              <h4 class="font-weight-bolderalign-middle mb-0">
                Features
                <b-badge pill variant="warning" class="ml-0">{{
                  plan.features.length
                }}</b-badge>
              </h4>

              <b-form-checkbox
                checked="false"
                class="custom-control-primary ml-1"
                name="check-button"
                switch
                :aria-expanded="featuresListVisible ? 'true' : 'false'"
                :aria-controls="`collapse-features-${plan._id}`"
                @change="
                  () => {
                    featuresListVisible = !featuresListVisible;
                  }
                "
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeOffIcon" />
                </span>
              </b-form-checkbox>
            </span>
          </div>
        </div>
      </div>

      <div class="card-body pb-0 px-0">
        <b-collapse
          :id="`collapse-features-${plan._id}`"
          v-model="featuresListVisible"
          class="w-100"
        >
          <div class="table-responsive mb-0 w-100">
            <table role="table" class="table table-bordered table-condensed">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col" class="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="plan.features.length > 0">
                  <tr
                    role="row"
                    v-for="(feature, index) in plan.features"
                    :key="feature._id['$oid']"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>

                    <td role="cell" style="max-width: 20vw">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start w-100"
                      >
                        <p class="mb-0 font-weight-bold w-100">
                          {{ `${feature.title}` }}
                        </p>
                      </div>
                    </td>

                    <td role="cell" class="w-auto">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start w-100"
                      >
                        <p class="mb-0 font-weight-bold w-100">
                          {{ `${feature.description}` }}
                        </p>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex align-items-center justify-content-center w-100"
                        v-if="feature.unit && feature.unit != null"
                      >
                        <div
                          v-if="
                            feature.unit &&
                            feature.unit == 'count' &&
                            feature.quantity
                          "
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <label class="font-weight-bolder text-primary"
                            >Count</label
                          >
                          <b-badge variant="dark">{{
                            feature.quantity
                          }}</b-badge>
                        </div>

                        <div
                          v-else-if="
                            feature.unit &&
                            feature.unit == 'credit' &&
                            feature.tariff
                          "
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <label class="font-weight-bolder text-primary"
                            >Credits Per Unit</label
                          >
                          <b-badge variant="dark">{{ feature.tariff }}</b-badge>
                        </div>

                        <div
                          v-else-if="feature.unit && feature.unit == 'boolean'"
                          class="d-flex flex-column align-items-start justify-content-center"
                        >
                          <label class="font-weight-bolder text-success"
                            >Enabled</label
                          >
                          <b-form-checkbox
                            checked="true"
                            class="custom-control-success"
                            name="check-button"
                            switch
                            :disabled="true"
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Features Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        This plan contains no features.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-collapse>
      </div>
    </div>

    <div class="card mb-1">
      <div class="card-header pt-0 mb-0 mt-0 pb-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-12 mx-0 px-0"
          >
            <h4 class="font-weight-bolder mb-0">Pricing</h4>
            <p class="mb-50">
              Select a suitable pricing from the options below.
            </p>
          </div>
        </div>
      </div>

      <div class="card-body border pb-0 py-1">
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-center"
        >
          <template v-for="price in plan.prices">
            <div
              class="card border-primary cursor-pointer mx-3 mb-0"
              :class="{
                'border-success': selectedPriceId == price._id['$oid'],
                'bg-light-success': selectedPriceId == price._id['$oid'],
              }"
              :key="price._id['$oid']"
              style="width: 230px; height: 180px"
            >
              <div class="card-header pb-0">
                <div
                  class="d-flex flex-column align-items-center justify-content-start w-100"
                >
                  <h3 class="font-weight-bolder mb-75 text-center">
                    {{ price.name == "Annual" ? "Annually" : price.name }}
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <h1
                  class="text-center font-weight-bolder text-primary align-middle"
                  :class="{
                    'text-success': selectedPriceId == price._id['$oid'],
                  }"
                >
                  <feather-icon size="30" icon="DollarSignIcon" /><span
                    class="align-middle"
                    >{{ price.tariff }}</span
                  >
                </h1>
                <!-- <h5 class="text-center font-weight-bold">
                {{ price.duration }} Days
              </h5> -->
              </div>

              <div class="card-footer py-75">
                <div
                  class="w-100 d-flex align-items-center justify-content-center"
                >
                  <b-button
                    pill
                    :variant="
                      selectedPriceId == price._id['$oid']
                        ? 'success'
                        : 'primary'
                    "
                    @click="handleBuy(price._id['$oid'])"
                    ><feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                      v-if="selectedPriceId == price._id['$oid']"
                    />{{
                      selectedPriceId == price._id["$oid"]
                        ? "Selected"
                        : "Select"
                    }}</b-button
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import {
  BButton,
  BBadge,
  BCollapse,
  VBToggle,
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    FeatherIcon,
    BButton,
    EmptyTableSection,
    BBadge,
    BCollapse,
    VBToggle,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    required,
  },
  props: {
    plan: { type: Object, required: true },

    handlePriceSelected: {
      type: Function,
      required: true,
    },

    selectedPriceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      featuresListVisible: false,
      notes: null,
    };
  },
  mounted() {
    // this.load();
  },
  methods: {
    // load() {
    //   if (this.plan.prices.length === 1) {
    //     this.handleBuy(this.plan.prices[0]._id["$oid"]);
    //   }
    // },

    // handleNoteChange(note){
    //   this.$emit('on-change', note)
    // },

    handleBuy(id) {
      this.handlePriceSelected(id);
    },
  },
};
</script>

<style>
</style>